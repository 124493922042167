.published-form-box {
  height: 250px;
  border-radius: 5px;
  color: #404040;
  padding: 00.5rem !important;
  font-size: 110%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scheduled-call-button {
  background-color: #667e92;
  color: white;
  border-radius: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 1.9rem;
  cursor: pointer;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.flex-box > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 5px;
}

.survey-info > div {
  text-align: end;
}
