.save-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 3px;
  background-color: #00c194;
  border-color: #00c194;
}

.reg-questions-container {
  margin-bottom: 24px;
  flex-grow: 1;
  max-width: 900px;
}