.h3 {
  text-align: center;
}
@media (max-width: 610px) {
  .h3 {
    font-size: small;
    text-align: center;
  }
}

.calendar {
  font-size: 9px !important;
}

@media (max-width: 310px) {
  .calendar {
    font-size: 8px !important;
  }
}

.lb-container {
  background-color: #ecf9fa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5em;
  width: 100%;
  margin-top: 1.2em;
}

.lb-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

@media (max-width: 768px) {
  .lb-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.lb-container-col {
  background-color: #ecf9fa;
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 8px;
  column-gap: 8px;
  flex-wrap: wrap;
  padding: 1.5em;
  width: 100%;
  margin-top: 1.2em;
}

.lb-container-col > hr {
  grid-column: 1/3;
}

/* @media (max-width: 530px) {
  .lb-container-col {
    background-color: #ecf9fa;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    flex-wrap: wrap;
    padding: 1.5em;
    width: 100%;
    margin-top: 1.2em;
  }
} */

.date-div-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  justify-items: center;
  padding: 1em;
  row-gap: 8px;
  column-gap: 8px;
}

.date-div {
  border: 1.2px solid #004479;
  width: 100%;
  text-align: center;
  padding: 0.4em;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 380px) {
  .date-div-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    max-width: 247.5px;
    width: 247.5px;
    justify-items: center;
    margin: 1em;
    row-gap: 4px;
    column-gap: 4px;
  }
  .date-div {
    width: 60px;
    text-align: center;
    padding: 0.4em;
    border-radius: 5px;
  }
}

.input-container {
  display: flex;
  align-items: center;
}

.db-text > h3,
.db-text > p {
  margin: 0;
  line-height: 20px;
}

.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  width: 4.7em;
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  appearance: textfield !important;
}

.input-box:focus {
  box-shadow: 0 0 0.2rem rgba(0, 123, 255, 0.25);
  /* border: 0.2em solid #80bdff;
  border-right-width: 0.2em !important ; */
  outline: 0;
  color: #495057;
}

.input-box:hover {
  /* border: 0.2em solid #80bdff;
  border-right-width: 0.2em !important ; */
}

.input-icon {
  font-size: large;
  margin-left: 0.4em;
  -webkit-user-select: none;
}
.input-icon:hover {
  color: #71cfd7;
}
