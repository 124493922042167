.grid-container {
  display: flex;
}

.grid-container > div {
  margin: auto 2px;
}

/* .action-icon {
  color: #404040;
  font-size: large;
  margin-left: 5px;
} */

.gridInput {
  display: flex;
}

.fields > div.controls {
  margin: auto 5px;
  min-width: 84px;
}

.choiceNumber {
  margin: auto 2px;
}

.fields {
  display: flex;
  flex-grow: 1;
}

.fields > div,
.fields > span {
  margin: 2px;
}

.row {
}

@media (max-width: 500px) {
  .grid-container {
  }
  .row {
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

.grid-container .mcq-checkbox {
  visibility: hidden;
}

.grid-container .mcq-checkbox :global .ant-checkbox .ant-checkbox-inner {
  transition: visibility 0s;
}

.grid-container :global .action-icon.action-icon-checkbox {
  margin-right: 10px;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
  .grid-container .mcq-checkbox {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover .mcq-checkbox {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
