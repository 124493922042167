.grid-container {
  display: flex;
}

.grid-container input {
  color: #004479;
}

.fields {
  display: flex;
  flex-grow: 1;
}

.inputFields {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.firstField {
  display: flex;
  flex-grow: 2;
}

.secondField {
  flex-grow: 4;
}

.fields > div,
.inputFields > div {
  margin: 2px;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
}

.uploadButton :global .ant-radio-button-wrapper {
  text-align: center;
  height: auto;
  line-height: inherit;
  font-size: smaller;
}

.controlFields input {
  text-align: center;
}

.controls span {
  margin-right: 4px;
}

.gridInput {
  display: flex;
}

.choiceNumber {
  margin: auto 2px;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .fields {
  }
  .inputFields {
  }
  .choiceNumber {
    text-align: center;
  }
  .firstField {
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
  .inputFields {
  }
  .firstField {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
