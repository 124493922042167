.grid-container {
  display: grid;
  column-gap: 8px;
  padding: 10px 0px 10px 32px;
  grid-column: 2/4;
  width: 100%;
}
@media (max-width: 500px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 8px;
    row-gap: 8px;
  }
}

@media (min-width: 1225px) {
  .grid-container {
    display: grid;
    column-gap: 8px;
  }
}
