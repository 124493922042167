.select-arrow {
  color: #004479;
  font-weight: bold;
}
/* .select-arrow-active {
  color: white;
} */
.dropdown-button {
  border: 1.25px solid #004479;
  border-radius: 5px;
  width: 100%;
  color: #004479;
  overflow: hidden;
  transition: none!important;
  display: table-cell;
  font-weight: bold;
}
/* .dropdown-button-active, .dropdown-button-active:hover, .dropdown-button-active:active {
  background-color: #004479!important;
  color: white!important;
} */
:global .ant-select-dropdown {
  width: auto !important;
}