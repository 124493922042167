.ant-checkbox-wrapper.special .ant-checkbox-inner {
  width: 22px;
  height: 22px;
  border: 1px solid #004479;
}

.choice-numberCircle {
border-radius: 50%;
padding: 3px 7px;
background: #fff;
border: 1px solid #004479;
color: #004479;
text-align: center;
font: 12px Arial, sans-serif;
}

@media only screen and (max-width: 1024px) {
  .ant-checkbox-wrapper.special .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid #004479;
  }
}

@media only screen and (max-width: 786px) {
  .choice-numberCircle {
    padding: 2px 5px;
    font: 12px Arial, sans-serif;
  }
}