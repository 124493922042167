.note-box {
  padding: 10px;
  border-radius: 5px;
  margin: 8px 0;
}

.note-box-header > div {
  margin-right: 5px;
}

.note-box-header .number {
  width: 25px;
  height: 25px;
  text-align: center;
  border: 1px solid;
  border-radius: 15px;
  font-weight: bold;
  color: white;
  background: #1a4375;
}

.edit-buttons button {
  margin: 0px 5px;
  border-radius: 10px;
  font-size: 12px;
}
