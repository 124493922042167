.survey-container {
  background-color: #0a3157;
  color: white;
  padding: 10px;
  min-height: 100%;
}

.user-video-screen {
  max-width: unset;
  width: 200px;
  border: 1px solid white;
  padding: 5px;
  border-radius: 5px;
}

.linkCard :global .ant-card-body {
  padding: 0px !important;
}

.linkCard {
  width: min-content;
  min-width: 350px;
  border-radius: 50px;
  background-color: #0c4674;
  border: 1px solid #34c1ae;
  color: white;
  margin-top: 16px;
}

.linkButton {
  border: none;
  background-color: #34c1ae;
  height: auto;
  border-radius: 50px;
  min-width: 100px;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.flex-end {
  justify-content: flex-end;
}

.nav-button {
  background-color: #004479;
  color: white;
  border-radius: 3px;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: auto;
}

.nav-button:focus {
  background-color: #004479;
  color: white;
  border-color: white;
}

.nav-button:hover {
  background-color: #004479;
  color: white;
  border-color: white;
}

.center {
  width: 100%;
  max-width: 550px;
  margin: 0px auto 90px;
}

.end_center {
  width: 100%;
  max-width: 550px;
  margin: 0px auto 90px;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
}

.numberCircle {
  width: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
  color: white;
  margin-right: 5px;
}

.answerBox {
  max-width: 800px;
  margin: 30px auto 0px;
}

.quit-button {
  padding: 12px;
  background: #0c4675;
  color: white;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  position: fixed;
  top: 10px;
  right: 10px;
}

.logo {
  position: fixed;
  top: 10px;
  left: 10px;
}

.mobile_topbar {
  background-color: #0a3157;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}

.mobile_quit-button {
  padding: 12px;
  background: #0c4675;
  color: white;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.bottom-buttons {
  padding: 8px 10px;
  background: #34c1ad;
  color: white;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.bottom-bar {
  background-color: #072742;
  padding: 10px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.bottom-wrapper {
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  padding: 10px 20px;
}

.question-box {
  color: white;
  background-color: #0c4675;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  width: 100%;
  line-height: 1.2;
}

.question-box-1 {
  color: white;
  background-color: #0c4675;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  line-height: 1.2;
}


.options-box {
  color: white;
  background-color: #0c4675;
  padding: 30px 20px;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
}

@media only screen and (max-width: 960px) {
  .end_center {
    height: calc(100vh - 250px);
  }
}

@media only screen and (max-width: 800px) {
  .mobile_quit-button {
    top: 10px;
    right: 20px;
  }

  .mobile_logo {
    top: 10px;
    left: 20px;
  }
  .bottom-buttons {
    width: 120px;
  }
}
