.grid-container {
  align-items: center;
  max-width: 600px;
  margin: auto;
}

.inputFields > div {
  display: flex;
  margin: 10px 0;
}

.inputFields > div > span {
  min-width: 100px;
}

.inputFields > div > div {
  flex-grow: 1;
}

.slider {
  grid-column: 1/4;
}

.text {
  grid-column: 1/2;
}

.input {
  grid-column: 2/4;
}

.span-all-cols {
  grid-column: 1/4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.span-all-cols > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.span-all-cols > div > p {
  margin: 0 0.5em 0 0;
  text-align: center;
}

.likert-labels {
  display: flex;
  justify-content: space-between;
}

.likert-label {
  width: 100px;
  word-break: break-word;
  text-align: center;
  padding: 5px;
}

@media (max-width: 500px) {
  .grid-container {
  }

  .text {
    display: none;
  }

  .input {
    grid-column: 1/2;
  }

  .slider {
    grid-column: 1/4;
  }

  .span-all-cols {
  }

  .span-all-cols > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 8px;
  }
}

@media (min-width: 1225px) {
  .likert-label {
    width: 200px;
  }
}
