.survey-container {
  background-color: #004479;
  color: white;
  padding: 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.bottom-elements {
  bottom: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #004479;
}

.flex-end {
  justify-content: flex-end;
}

.nav-button {
  background-color: #004479;
  color: white;
  border-radius: 3px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.nav-button:focus {
  background-color: #004479;
  color: white;
  border-color: white;
}

.nav-button:hover {
  background-color: #004479;
  color: white;
  border-color: white;
}

.center {
  text-align: center;
  margin-top: 75px;
  font-size: 18px;
  flex-grow: 1;
}

.numberCircle {
  width: 26px;
  line-height: 26px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  border: 1px solid white;
  color: white;
  margin-right: 5px;
}

.answerBox {
  max-width: 800px;
  margin: 30px auto 0px;
}

@media only screen and (max-width: 768px) {
  .center {
    font-size: 14px;
  }
}
