.addInfluencers-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  padding: 10px;
}

@media only screen and (max-width: 1000px) {
  .addInfluencers-grid-container {
    grid-template-columns: auto;
  }
}