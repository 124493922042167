.home-question-answers .ant-row{
    min-height: 100vh;
}

.home-question-answers h1{
    margin-top: 30px;
    font-size: 39px;
    max-width: 434px;
}

.home-question-answers .image-container{
    padding: 20px;
    text-align: center;
}

.home-question-answers .image-container img{
    width: 100%;
    max-width: 600px;
}

.home-question-answers .question-answer-collapse{
    padding-right: 20px;
}

.home-question-answers .question-answer-collapse .ant-collapse-item{
    border-bottom: 1px solid #717171;
}

.home-question-answers .question-answer-collapse .ant-collapse-item .ant-collapse-header{
    background: none;
    padding: 20px 30px;
    padding-left: 62px;
    font-size: 22px;
}

.home-question-answers .question-answer-collapse .ant-collapse-item .ant-collapse-header img.ant-collapse-arrow{
    height: 30px;
}

.home-question-answers .question-answer-collapse .ant-collapse-content-box{
    padding-top:0px !important;
}

.home-question-answers .question-answer-collapse p{
    font-size: 19px;
    padding-left: 48px;
}

@media only screen and (max-width: 767px) {
    .home-question-answers .question-answer-section{
        width: 100%;
        padding: 20px;
    }
    .home-question-answers h1{
        max-width: 100%;
        font-size: 35px;
    }
}
