@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");

.chat-component,
.chat-component > div {
  height: 100%;
}

.chat-component :global .ant-modal-close-x {
  color: white;
  width: auto;
  height: auto;
  line-height: 16px;
  margin: 5px;
}

.chat-component :global .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.chat-component :global .ant-drawer-body {
  padding: 5px;
}

.chat-component :global .ant-drawer-wrapper-body {
  background-color: #f09986;
}

.chat-component :global .ant-drawer-footer {
  box-shadow: 0px 0 10px rgb(255 255 255 / 80%);
  border-top: none;
}

.chat-component .modal-body {
  position: relative;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
}

.chat-component .drawer-button {
  position: relative;
  /* top: 0; */
  /* right: 0; */
  /* width: 3%; */
  height: 100%;
  background-color: #f09986;
  cursor: pointer;
  text-align: center;
  width: 50px;
}

.chat-component .bottom-bar :global button {
  color: #1a4375;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  border: none;
}

.chat-component .bottom-bar :global button + button {
  margin-left: 5px;
}

.chat-component :global .ant-drawer-content-wrapper {
  position: relative;
}
