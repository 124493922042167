.survey-state-modal :global .ant-modal-content {
  padding: 20px;
}

.survey-state-modal :global .ant-modal-body {
  padding: 0px;
}

.survey-state-modal :global .ant-modal-content .ant-modal-close-x {
  color: black;
}

.survey-state-modal .buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.survey-state-modal :global .ant-modal-footer {
  padding: 20px 0 0 0;
}

.button,
.button:hover,
.button:focus {
  color: white;
  border: none;
  background-color: #00233f;
  height: 36px;
  min-width: 178px;
  border-radius: 4px;
  text-transform: uppercase;
}

.participant-box {
  padding: 20px 20%;
  background: #ecf9fa;
  text-align: start;
  margin: 20px 0;
  border-radius: 10px;
}

.participant-box > div > span:first-child {
  min-width: 160px;
  display: inline-block;
  text-align: end;
}

.input-container {
  align-items: center;
}

.input-container :global .ant-input-number-handler-wrap {
  opacity: 1;
}

.input-box {
  width: 4.7em;
  padding: 0.5rem;
  font-size: 16px;
  border-radius: 5px;
  background-color: #dddddd;
}

.input-box :global input {
  text-align: center;
}

.input-box:focus {
  box-shadow: 0 0 0.2rem rgba(0, 123, 255, 0.25);
  /* border: 0.2em solid #80bdff;
    border-right-width: 0.2em !important ; */
  outline: 0;
  color: #495057;
}

.input-icon {
  font-size: large;
  margin-left: 0.4em;
  -webkit-user-select: none;
}
.input-icon:hover {
  color: #71cfd7;
}

.price-box {
  text-align: left;
  margin: 10px 0;
}

.price-box > div {
  font-size: larger;
}

.action-title {
  font-size: 16px;
  font-weight: bold;
}

.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-info-text {
  flex-basis: 60%;
}

@media screen and (max-width: 500px) {
  .participant-box > div span:first-child {
    max-width: 120px !important;
    display: inline-block;
    text-align: end;
  }
  .participant-box {
    padding: 20px 5px;
  }
  .survey-state-modal :global .ant-modal-content {
    padding: 30px 10px;
  }
}
