.next-button{
    font-size: 18px;
    background-color:  #F2F2F2;
    border: 0;
    color: black;
    height: max-content;
    width: 20%;
    border-radius: 5px;
    padding: 2% 4%;
  }

  .next-button:hover{
    font-size: 18px;
    background-color:  #667E92 ;
    border: 0;
    color: white;
    height: max-content;
    width: 20%;
    border-radius: 5px;
    padding: 2% 4%;
  }