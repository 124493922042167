.miscButtons {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  flex: calc(100% / 4);
}
.miscButtonsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 10px;
}
.miscButtons:hover {
  background-color: #f0f0f0;
}

.flexObject {
  flex: calc(100% / 4);
}
.labelDetails {
  font-size: "0.7rem";
  opacity: "80%";
}
