.grid-container {
  display: grid;
  grid-template-columns: 1fr 135px;
  column-gap: 8px;
}
.fields {
  display: grid;
  grid-template-columns: 2fr 8fr;
  column-gap: 8px;
}
.fields :global .uploadButton .ant-upload.ant-upload-select,
.fields :global .uploadButton .ant-upload.ant-upload-select .button {
  width: 100%;
}
.fields :global .uploadButton .ant-upload.ant-upload-select .button {
  padding: 10px;
}
@media (max-width: 500px) {
  .grid-container {
    display: grid;
    grid-template-columns: 7fr 1fr;
    column-gap: 8px;
    row-gap: 8px;
  }
}

@media (max-width: 650px) {
  .fields :global .uploadButton .ant-upload.ant-upload-select .button {
    padding: 1px 12px;
  }
}

@media (min-width: 1225px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 200px;
    column-gap: 8px;
  }
  .fields {
    grid-template-columns: 2fr 8fr;
  }
}
