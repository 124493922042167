@import "~antd/dist/antd.less";

div[class*="survey"] {
  .ant-checkbox,
  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #d9d9d9;
    }

    &.ant-checkbox-checked::after {
      border-color: #d9d9d9;
    }
  }

  .ant-checkbox + span {
    padding-left: 16px;
  }

  .ant-radio + span {
    padding-left: 16px;
  }

  .ant-radio,
  .ant-radio:hover {
    .ant-radio-inner {
      border-color: white;
    }
  }
}

@primary-color: #004479;