.profile {
  color: #404040;
  font-size: 18px;
  margin-bottom: 25px;
  max-width: 850px;
}
.profile .ant-input {
  background-color: #eee;
}

@media only screen and (max-width: 450px) {
  .profile {
    font-size: 14px;
  }
}

.verify-button{
  border:none;
  background-color:#F2F2F2;
  box-shadow:0 1px 2px rgb(0 0 0 / 20%);
  color: #404040;
}

.verify-button:hover,.verify-button:focus,.verify-button:active{
  background-color:#FF9480;
}

.verified-button{
  border:none;
  background-color:#71CFD7;
  box-shadow:0 1px 2px rgb(0 0 0 / 20%);
  color: #FFFF;
}
.verified-button:focus,.verified-button:hover,.verified-button:active{
  border:none;
  background-color:#71CFD7;
  box-shadow:0 1px 2px rgb(0 0 0 / 20%);
  color: #FFFF;
}