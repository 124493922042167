.grid-container {
  display: flex;
}

.action-icon {
  margin: auto 0px;
}

.inputFields {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
}

.inputFields > div {
  flex-grow: 1;
  margin: 5px;
}

.fields {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
}

.fields > div {
  margin: 2px;
}

.controlFields {
  display: flex;
}

.controlFields > span {
  margin: 6px 2px;
}

.uploadButton :global .ant-upload {
  height: 100%;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
}

.controlFieldsSpecial {
}

.gridInput {
  display: flex;
}

.controlFieldsSpecial .gridInput {
}

.choiceNumber {
  margin: auto 2px;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .fields {
  }
  .inputFields {
  }
  .controlFields {
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
  .controlFields {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}

