.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  color: #495057;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.input-box :global input {
  background-color: #f5f5f5;
}

.input-box:focus {
  border: none;
}
.input-box:hover {
  border: none;
}

.access-selector {
  width: 144px;
}

.access-selector :global .ant-select-selector {
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  color: #01223f;
}
