.question-summary-box {
  margin: 16px 0;
  padding: 16px 10px;
  border-bottom: 1px solid #dddddd;
  border-radius: 0px;
  color: black;
}

.question-summary-box .question {
  display: grid;
  grid-template-columns: 30px 1fr;
  column-gap: 8px;
}

.question-summary-box .question .question-number {
  text-align: center;
  /* padding: 0 5px; */
  height: 30px;
  width: 30px;
  font-size: 14px;
  /* vertical-align: middle; */
  border-radius: 20px;
  background-color: #01223f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-summary-box .question .question-number span {
  vertical-align: middle;
}

.response-box .label-groups {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.response-box .labels {
  display: inline-block;
  margin: 0 5px;
}

.response-box .square-groups {
  display: flex;
  justify-content: center;
}

.response-box .square-groups > div {
  width: 40px;
  padding: 10px;
  text-align: center;
  border: 1px solid #01223f;
}

.response-box .options .radio-groups {
  display: flex;
  justify-content: space-around;
  max-width: 500px;
  margin: auto;
}

.response-box .radio-buttons {
  height: 25px;
  width: 25px;
  display: inline-block;
  border: 1px solid #01223f;
  border-radius: 15px;
  vertical-align: middle;
}

.response-box .check-boxes {
  height: 25px;
  width: 25px;
  display: inline-block;
  border: 1px solid #01223f;
  vertical-align: middle;
}

.response-box .comment-box {
  margin: 5px;
  padding: 5px;
  border-radius: 8px;
  background-color: #dddddd;
}

.response-box .image-video-block {
  display: inline-block;
  /* border: 1px solid #01223f; */
  margin: 5px;
}

.response-box .image-video-block img,
.response-box .image-video-block video {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
}

/* .response-box .image-video-block video {
  max-width: 100px;
} */

.response-box .options > div {
  margin: 5px 0;
}

.response-box .star-rating-options {
  display: inline-block;
  text-align: center;
}

.response-box .star-rating-options .labels {
  display: block;
}

.response-box .star-rating-options :global .anticon {
  font-size: 20px;
  color: #01223f;
}

.overall-report {
  margin: 10px 0;
}

.overall-report :global .report-buttons {
  display: flex;
  justify-content: space-between;
}

.overall-report :global .sentiment-report {
  margin: unset;
  margin-left: auto;
}

.overall-report :global .report-wrapper {
  justify-content: space-between;
}

@media (max-width: 600px) {
  .response-box .square-groups > div {
    width: 25px;
    padding: 5px;
  }
}

@media (max-width: 450px) {
  .response-box .radio-groups span {
    height: 15px;
    width: 15px;
  }
  .response-box .label-groups {
    font-size: 10px;
  }
  .response-box .square-groups > div {
    font-size: 15px;
    padding: 0px;
    width: 20px;
  }
}

@media (max-width: 350px) {
  .response-box .square-groups > div {
    font-size: 10px;
    padding: 0px;
    width: 15px;
  }
  .client-link :global button {
    min-width: 150px !important;
    max-width: 200px;
    height: auto;
  }
  .client-link :global button span {
    white-space: break-spaces;
  }
}
.miscButtons {
  cursor: pointer;
  padding: 10px;
}
.miscButtons :hover {
  background-color: #f0f0f0 !important;
}
