.zoom-image .ant-modal-body {
  padding: 0;
  text-align: center;
  margin: 20px;
  position: relative;
}

.zoom-image .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.imgLabel {
  color: white;
  word-break: break-word;
}

.img-container {
  width: 120px; 
  height: 120px; 
  overflow: hidden; 
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid white;
}

.img-container > .img-element {
  width: 120px;
  height: 120px;
}

@media only screen and (max-width: 800px) {
  .img-container {
    width: 60px;
    height: 60px;
  }
  .img-container > .img-element {
    width: 60px;
    height: 60px;
  }
}