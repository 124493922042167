.survey-container {
  background-color: #004479;
  color: white;
  padding: 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 18px;
}

.title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.center {
  margin-top: 60px;
  padding: 0px 60px;
  width: 85%;
  margin: auto;
}

.box {
  display: block;
  color: white;
  padding: 10px 40px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.date-box {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: auto;
  height: 100%;
  justify-content: space-around;
}

.time-selector > div {
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: 1fr 8fr;
  grid-column-gap: 8px;
}

@media only screen and (max-width: 768px) {
  .center {
    padding: 0px 20px;
    width: 100%;
    font-size: 14px;
  }
}
