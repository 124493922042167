.question-container {
  position: relative;
  padding: 20px 45px;
  border-radius: 6px;
  background-color: #fff;
  color: #004479;
  border: 1px solid #004479;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.close-icon {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 26px;
  cursor: pointer;
  background: white;
}

.question-numberCircle {
  border-radius: 50%;
  padding: 5px 10px;
  background: #fff;
  border: 2px solid #004479;
  color: #004479;
  text-align: center;
  font: 18px Arial, sans-serif;
}

.actions {
  display: flex;
  align-items: center;
  opacity: 0;
  padding-bottom: 24px;
  width: 200px;  
}

.no-hover {
  opacity: 1;
}

.actions:hover {
  opacity: 1;
}

.ant-switch-checked {
  background-color: #00c194;
}

.action-icon {
  font-size: 26px;
  margin-right: 16px;
  cursor: pointer;
  color: #004479;
}

.add-between-question {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  height: 30px;
}

.add-icon {
  opacity: 0;
}

.add-icon:hover {
  opacity: 1;
}

@media only screen and (max-width: 1185px) {
  .question-container {
    padding: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .action-icon {
    font-size: 18px;
    margin-right: 6px;
  }
  .close-icon {
    font-size: 20px;
    right: -7px;
  }
}

@media only screen and (max-width: 504px) {
  .actions {
    width: auto;
  }
}

@media only screen and (max-width: 786px) {
  .actions {
    opacity: 1;
  }
  .ant-checkbox-wrapper.special .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1px solid #004479;
  }
}

.slider label {
  color: #004479;
}