.layout-container{
    position: relative;
}

.layout-container .main-container{
    padding-top: 78px;
}

@media only screen and (max-width: 767px) {
    .layout-container .main-container{
        padding-top: 47px;
    }
}
