.dropdown.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #0A3157;
  color: white;
}

.dropdown .ant-select-arrow {
  color: white;
}

.dropdown.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #0A3157;
}

.dropdown.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #0A3157;
}