.nps .ant-radio-button-wrapper {
  color: white; 
  background-color: #004479;
}

.nps .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #004479;
  background-color: white;
  border-color: white;
}

.bottom-mark {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.bottom-label {
  display: inline-block;
  font-size: 14px;
}

@media only screen and (max-width: 458px) {
  .nps .ant-radio-button-wrapper {
    padding: 0px 10px;
    font-size: 12px;
  }

  .bottom-label {
    display: inline-block;
    font-size: 12px;
  }
}

@media only screen and (max-width: 350px) {
  .nps .ant-radio-button-wrapper {
    padding: 0px 6px;
  }

  .bottom-label {
    display: inline-block;
    font-size: 10px;
  }
}