.video-container {
    margin: 0px auto;
    height: 120px;
    width: 120px;
    border: 1px solid white;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.video-container .video-preview {
  width: 118px;
  height: 118px;
}

.video-modal .ant-modal-body {
  padding: 0px;
  position: relative;
}
.video-modal .ant-modal-content {
  background-color: transparent;
}

@media only screen and (max-width: 800px) {
    .video-container {
      width: 60px;
      height: 60px;
    }
    .video-container .video-preview {
      width: 58px;
      height: 58px;
    }
  }