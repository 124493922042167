.grid-container {
  display: flex;
}

.action-icon {
  margin: auto 0px;
}

.gridInputs {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.gridInputs > div {
  flex-grow: 1;
  margin: 5px;
}

.secondField {
  display: flex;
}

.secondField > span {
  margin-right: 5px;
}

.uploadButton :global .ant-upload {
  height: 100%;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
  margin: 0;
  width: 70px;
  white-space: normal;
  padding: 0px 15px;
  font-size: 12px;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .gridInputs {
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
}
