.survey-checkboxes {
  text-align: left;
}

.survey-checkboxes .ant-checkbox-group-item {
  font-size: 16px;
  color: #004479;
}

.survey-checkboxes .ant-checkbox-inner {
  height: 16px;
  width: 16px;
  background-color: #004479;
}

.survey-checkboxes .ant-checkbox-checked .ant-checkbox-inner {
  border-color: white;
}

.survey-checkboxes .ant-checkbox {
  align-self: baseline;
}

.survey-checkboxes .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
