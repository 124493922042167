.transcript-video-modal {
  max-width: 100vw;
  height: 100vh;
  border: none;
  margin: 0px;
}

.transcript-video-modal :global .ant-modal-content {
  border-radius: 0px;
}

.transcript-video-modal :global .ant-modal-body {
  height: 100vh;
  background-color: #0a3157;
  padding: 0px;
}

.transcript-video-modal .video-modal-header {
  background-color: #0c4675;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: white;
  height: 60px;
}

.transcript-video-modal .video-modal-header .participant-title {
  font-size: 18px;
}

.transcript-video-modal .video-modal-body {
  height: calc(100vh - 60px);
  display: flex;
}

.video-modal-body .video-reel-container {
  flex-basis: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.video-reel-container .video-container {
  width: 100%;
  height: 80%;
  padding: 20px;
}

.video-reel-container .reels-container {
  height: 20%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.reels-container .record-reel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px;
  padding: 4px 20px;
  align-items: center;
}

.reels-container .reel-container {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  max-width: 200px;
  min-width: 200px;
  padding: 4px 0px;
  align-items: center;
}

.reel-container .reel-wrapper {
  max-height: calc(100% - 44px);
  position: relative;
}

.reel-wrapper .reel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #00000080;
  color: white;
  z-index: 2;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.reel-wrapper:hover .reel-overlay {
  opacity: 1;
}

.edit-time-input {
  max-width: 50px;
  margin: 0 2px;
  background-color: #0a3157;
  border-color: rgb(130, 163, 182);
  border-radius: 4px;
  color: rgb(130, 163, 182);
}
.edit-text-input {
  background-color: #0a3157;
  color: white;
  border-radius: 4px;
  border-color: rgb(130, 163, 182);
  margin-top: 8px;
}

.add-transcript-check {
  margin-top: 10px;
  color: rgb(130, 163, 182);
}

.confirmation-modal :global .ant-modal-content .ant-modal-body {
  padding: 20px;
}
