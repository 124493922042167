.radioButton .ant-radio-inner {
  height: 18px;
  width: 18px;
  color: #404040;
}

.radioButton .ant-radio-inner::after {
  height: 16px;
  width: 16px;
  color: #404040;
}

.profileForm .ant-input-affix-wrapper > input.ant-input {
  background-color: #eee;
}

.contact_method .ant-checkbox-group-item {
  font-size: 16px;
  color: #404040;
  color: #404040;
  padding-right: 5%;
}

.contact_method .ant-checkbox-inner {
  height: 18px;
  width: 18px;
  color: #404040;
}

@media only screen and (max-width: 450px) {
  .contact_method .ant-checkbox-group-item {
    font-size: 14px;
  }

  .radioButton .ant-radio-inner {
    height: 14px;
    width: 14px;
  }

  .radioButton .ant-radio-inner::after {
    height: 6px;
    width: 6px;
  }

  .contact_method .ant-checkbox-inner {
    height: 14px;
    width: 14px;
  }
}
.option {
  padding: 10px;
  background-color: #ffff;
  margin-right: 3%;
  color: #404040;
  min-width: 110px;
  margin-bottom: 3%;
  margin-left: 0px !important;
}
