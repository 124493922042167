.association-box {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

.association-box:last-child {
    margin-bottom: 0px;
}

.association-box .list-item-text::-webkit-scrollbar {
    -webkit-appearance: none;
}

.association-box .list-item-text::-webkit-scrollbar:vertical {
    width: 7px;
}

.association-box .list-item-text::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
}