.grey-container {
  background-color: #f5f5f5;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
}

/* body {
  background-color: #f5f5f5;
} */

.survey-select {
  width: 100%;
}

.survey-select :global .ant-select-selector {
  box-shadow: 1px 3px 15px 12px rgba(238, 238, 238, 0.4);
  border: none !important;
  height: 48px !important;
  align-items: center;
  border-radius: 8px !important;
}



.green-button,
.green-button:hover,
.green-button:active,
.green-button:focus {
  background-color: #8edad0;
  color: white;
  border-radius: 4px;
  border: none;
}

.green-button + .green-button {
  margin-left: 12px;
}

.filter-dropdown :global .ant-cascader-menu {
  height: auto;
  min-width: 164px;
}

.filter-dropdown
  :global
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.filter-dropdown
  :global
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: #bedff780;
}

.print-body button {
  display: none;
}

.filter-dropdown {
  border-radius: 8px;
}
