.image-ranking-box {
  background: #0b385f; 
  padding: 10px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
}

.image-ranking-draggable-box {
  margin-bottom: 20px;
}

.image-ranking-draggable-box:last-child {
  margin-bottom: 0px;
}

.image-ranking-numberCircle {
  border-radius: 50%;
  padding: 3px 7px;
  border: 1px solid white;
  color:white;
  text-align: center;
  font: 12px Arial, sans-serif;
}