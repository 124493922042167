.logic-dropdown.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}

.branching-logic-container .ant-select-selector {
  background-color: #eee !important;
  align-items: center;
  font-size: 14px;
}
