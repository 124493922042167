.ant-card-body {
  padding: 18px !important;
}

.radio-buttons {
  margin: auto 0;
}

.ant-list-pagination {
  margin-top: 24px;
  padding: 0 20px;
  text-align: left;
}