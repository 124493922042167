.home-text-banner{
    background: #014579;
    color: white;
    min-height: 200px;
    height: calc(100vh - 78px);
}

.home-text-banner h1,
.home-text-banner h2{
    color: white;
}
.home-text-banner .signup-button{
    background: #70ced7;
    border: none;
    border-radius: 3px;
    color: white;
    padding: 17px 35px;
    font-size: 16px;
    height: auto;
}
.home-text-banner .text-container .ant-col,
.home-text-banner .text-container-bottom .ant-col{
    padding: 20px;
    text-align: center;
}
.home-text-banner .text-container-bottom{
    height: calc(100vh - 78px);
}
.home-text-banner .left-header-h1{
    font-size: 45px;
    font-weight: bold;
    max-width: 385px;
    display: inline-block;
    margin-bottom: 25px;
}
.home-text-banner .left-content-text{
    font-size: 18px;
    max-width: 500px;
    display: inline-block;
}
.home-text-banner .center-container{
    margin: 0;
    width: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 767px) {
    .home-text-banner{
        height: auto;
    }
    .home-text-banner .text-container-bottom{
        height: auto;
    }
    .home-text-banner .left-header-h1{
        font-size: 35px;
    }
}
