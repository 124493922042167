.ant-tabs-ink-bar {
  background: #00c194;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border: none;
    /* border-bottom: 1px solid #f0f0f0; */
    content: '';
}