.table :global .ant-table.ant-table-small .ant-table-tbody > tr > td,
.table :global .ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 5px 5px;
  word-break: keep-all;
}

.question-text {
  /* padding: 10px; */
  margin: 10px 0;
  border-radius: 10px;
  color: black;
  font-size: 16px;
}

.question-text div.number {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  text-align: center;
  margin-right: 5px;
  background-color: #01213f;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.questionInfo {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  color: #212121;
}

.print-body button {
  display: none;
}

.question-type {
  font-size: 12px;
}

.chartAreaWrapper {
  height: auto;
  width: 500px;
  margin: auto;
}

.table :global .ant-table-content {
  overflow-x: auto;
}

:global .chart-type-buttons .ant-radio-button-wrapper-disabled {
  opacity: 0.3;
}

@media (max-width: 600px) {
  .chartAreaWrapper {
    height: 400px;
    width: 400px;
    margin: auto;
  }
}

@media (max-width: 400px) {
  .chartAreaWrapper {
    height: 320px;
    width: 320px;
  }
}
