.button {
  padding: 0.4rem 0.8rem;
  margin: 0.4rem;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

.button.selected {
  background-color: #71cfd7;
  color: white;
}

.option-button {
  color: #404040;
  margin: 0.6rem 0.8rem 0 0;
  cursor: pointer;
}

.button-icon {
  color: #00233f;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.option-button-icon {
  margin-right: 0.4rem;
  cursor: pointer;
}

.option-button-icon::after {
  color: #71cfd7;
  background-color: #71cfd7;
}

.button-icon.selected {
  color: white;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
}

.question-box {
  background-color: #f5f5f5;
  width: 100%;
  color: black;
  margin: 12px 0;
  padding: 12px;
  border-radius: 5px;
}

.lb-container {
  background-color: #ecf9fa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5em;
  width: 100%;
  margin: 1.2em 0;
}

.db-text > h3,
.db-text > p {
  color: #404040;
  margin: 0;
  line-height: 20px;
}

.grid-container {
  margin-top: 1.2em;
  display: grid;
  grid-template-columns: 1fr 13em 13em;
  align-items: center;
  row-gap: 0.5em;
  column-gap: 0.25em;
  width: 100%;
}

.email-tag {
  background-color: rgb(0, 35, 63);
  border: 0px;
  color: white;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

@media (min-width: 430px) and (max-width: 580px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr 13em;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
  }
}

.bold-text {
  font-weight: 700;
  color: #2c3e50;
  font-size: 1rem;
}

.add-question-button {
  width: 100%;
  background-color: #004479;
  color: white;
  font-weight: 700;
  height: 32px;
  margin-top: 1.25em;
  text-align: center;
  height: 40px;
}

@media (max-width: 320px) {
  .add-question-button {
    width: 100%;
    background-color: #004479;
    color: white;
    font-weight: 700;
    margin-top: 1.25em;
    text-align: center;
    height: 40px;
    font-size: 12px;
  }
}

.emails {
  width: 60%;
}

@media (max-width: 407px) {
  .emails {
    width: 100%;
  }
}

.add-question-button:focus,
.add-question-button:active {
  background-color: #004479;
  color: white;
}

.add-question-button:hover {
  background-color: #fff !important;
  color: #004479 !important;
}

.add-question-icon {
  font-size: 1.2em;
}

.grey-question-button {
  color: #004479;
  background-color: #d8d8d8;
}

.modal-container :global .ant-modal-body {
  padding-bottom: 2px;
}

.typography {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .typography {
    font-size: 16px;
  }
}

.additional-screening-question-button {
  width: 80%;
  height: 32px;
  background-color: #00233f;
  color: #ffff;
}

.import-type-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.import-type-button :global .ant-radio-button-wrapper {
  width: calc(100% / 3);
  text-align: center;
  color: black;
  background-color: #f5f5f5;
  border: none;
  height: auto;
}

.import-type-button
  :global
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #00233f;
  color: white;
}

.import-type-button
  :global
  .ant-radio-button-wrapper:not(:first-child)::before {
  height: calc(100% - 2px);
  top: 0px;
}

.user-email-input {
  background-color: #f5f5f5;
  border: none;
}
