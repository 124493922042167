.sidenav {
  position: fixed;
  left: 0px;
  top: 0;
  height: 100%;
  width: 17%;
  background-color: #00233f;
  padding: 20px;
  min-width: 237px;
  overflow: auto;
}

.sidenav-drawer {
  position: fixed;
  left: 0px;
  top: 0;
  height: 100%;
  width: 150px;
  background-color: #00233f;
  padding: 20px;
  min-width: 220px;
  overflow: auto;
}

.sidenav-item {
  background-color: #00233F;
  padding: 10px;
  cursor: pointer;
  margin: 15px 0;
  color: white;
  display: flex;
  font-weight: bold;
  border-radius: 5px;
}
.sidenav-item:hover {
  background-color: #1b3a62;
  color: white;
}

.active {
  background-color: #8EDAD0;
}

@media only screen and (max-width: 1000px) {
  .sidenav {
    display: none;
  }
}
