.footer-layout .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    font-size: clamp(0.9em, 5vw, 1.2em);
    position: absolute;
    bottom: 20px;
}

.footer-layout .content .section_1 {
    display: flex;
    flex-wrap: wrap;
}

.footer-layout .content .section_1 .footer_link:hover {
    opacity: 0.8;
}

.footer-layout .content .section_2 .footer_link_2 {
    padding: 1em;
    color: white;
}
.footer-layout .content .section_1 a {
    color: white;
    margin: 0em 1em;
    text-decoration: none;
}
.footer-layout .mobile-footer{
    display: none;
}

.footer-layout .mobile-footer .section_2{
    text-align: center;
}

.footer-layout .mobile-footer .section_2 .footer_link_2{
    display: block;
}

.footer-layout .mobile-footer .section_1 .right-text{
    text-align: right;
}

.footer-layout .mobile-footer .section_1 .left-text{
    text-align: left;
}

@media screen and (max-width: 850px) {
    .footer-layout .desktop-footer{
        display: none;
    }
    .footer-layout .mobile-footer{
        display: block;
        bottom: 0px;
    }
}
