.grid-container {
  display: flex;
}

.grid-container input {
  color: #004479;
}

.fields {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.fields > div,
.inputFields > div {
  margin: 2px;
}

.secondField > span:nth-child(1) {
  margin-right: 4px;
}

.inputFields {
  display: flex;
  flex-grow: 8;
  flex-wrap: wrap;
}

.inputFields > div {
  flex-grow: 1;
}

.secondField {
  display: flex;
}

.fields > div.controlFields {
  display: flex;
  margin: auto 4px;
}

.controlFields input {
  text-align: center;
}

.controls span {
  margin-right: 4px;
}

.gridInput {
  display: flex;
}

.choiceNumber {
  margin: auto 2px;
}

.percentageField {
  max-width: 100px;
}

.controls {
  margin: auto 5px;
  min-width: 50px;
}

:global .percentageField .ant-input-affix-wrapper-lg {
}

.uploadButton :global .ant-upload {
  height: 100%;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
  margin: 0;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .fields {
  }
  .controlFields {
  }
  .inputFields {
  }
  .choiceNumber {
    text-align: center;
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
