.grid-form-container {
  display: grid;
  flex-direction: column;
  row-gap: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.flex-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.form-box {
  height: 250px;
  width: 100%;
  background-color: #FFFFFF;
  border-color: #71CFD7;
  border-radius: 5px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  color: #404040;
  padding: 0.5rem;
  font-size: 110%;
}

@media (min-width: 300px) {
  .grid-form-container {
    display: grid;
    width: 90%;
    margin: 0% 5%;
    grid-template-columns: repeat(auto-fill, minmax(300px,1fr));
    column-gap: 30px;
    row-gap: 30px;
  }
}

.form-box-new {
  height: 250px;
  width: 100%;
  min-height: 200px;
  background-color: #FF9480;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 110%;
  text-align: center;
  cursor: pointer;
}
