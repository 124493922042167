.grey-container {
  background-color: #f5f5f5;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
}

.survey-select {
  width: 100%;
}

.survey-select :global .ant-select-selector {
  box-shadow: 1px 3px 15px 12px rgba(238, 238, 238, 0.4);
  border: none !important;
  height: 48px !important;
  align-items: center;
  border-radius: 8px !important;
}

.select-call {
  margin: 20px;
  width: 50%;
}
