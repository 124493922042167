.published-box {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 250px;
}

.published-box:hover {
  box-shadow: 0 10px 20px rgb(85 85 85);
  background-color: #f1f2f2;
}

.copy-img {
  background-image: url("/src/assets/copy.png");
}

.firstSection {
  display: flex;
  flex-direction: column;
}

.firstSection > div {
  margin-bottom: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  letter-spacing: 2px;
}

.survey-icons {
  display: flex;
  justify-content: flex-end;
  background: #e5e5e5;
  padding: 5px;
  border-radius: 5px;
  color: #747574;
  max-width: 180px;
}

.survey-icons > span,
.survey-icons > div {
  margin: 0 10px;
  width: 100%;
}

.survey-icons > span {
  min-width: 50px;
}

.survey-progress {
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
}

.secondSection {
  display: flex;
  justify-content: space-between;
}

.header img {
  height: 15px;
  cursor: pointer;
}

.action-buttons {
  display: flex;
}

.action-buttons > div {
  padding: 0px 5px;
  background: #e0e0e0;
  margin: 0 5px;
  border-radius: 5px;
  min-width: 30px;
  text-align: center;
}

.button {
  padding: 12px;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 10px;
  letter-spacing: 2px;
}

.button.disabled-button {
  background: #e0e0e0;
  pointer-events: none;
}

.schedule-call-button {
  background: #1a3b69;
  color: white;
  margin-right: 10px;
}

.view-result-button {
  background: #8edad0;
  color: white;
}

.surveyDetails > div {
  margin-bottom: 5px;
}
