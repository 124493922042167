/* .published-survey-popup :global .ant-modal-content .ant-modal-footer button {
  color: white;
  border: none;
  font-weight: bold;
  padding: 6.4px 30px;
}

.published-survey-popup
  :global
  .ant-modal-content
  .ant-modal-footer
  button
  + button {
  margin-left: 40px;
}

.published-survey-popup
  :global
  .ant-modal-content
  .ant-modal-footer
  .yesButton {
  background-color: #40e0d0;
}

.published-survey-popup :global .ant-modal-content .ant-modal-footer .noButton {
  background-color: #a5b3b8;
}

.published-survey-popup :global .ant-table-tbody > tr > td {
  padding: 2px;
  font-size: 12px;
}

.published-survey-popup :global .ant-table-thead .ant-table-cell {
  padding: 2px;
  font-size: 12px;
}

.published-survey-popup
  :global
  .ant-table.ant-table-small
  .ant-table-tbody
  > tr
  > td {
  word-break: break-word;
}

.published-survey-popup :global .ant-modal-body {
  padding: 16px;
}

.published-survey-popup :global .ant-modal-body {
  padding-bottom: 0;
} */

.published-survey-popup :global .ant-modal-content .ant-modal-footer button {
  color: white;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  height: 36px;
  font-size: 14px;
}

.published-survey-popup :global .ant-modal-footer {
  text-align: right;
}

.published-survey-popup :global .ant-table-wrapper {
  padding: 10px;
}

.published-survey-popup
  :global
  .ant-modal-content
  .ant-modal-footer
  button
  + button {
  margin-left: 20px !important;
}

.published-survey-popup
  :global
  .ant-modal-content
  .ant-modal-footer
  .yesButton {
  background-color: #00233f;
}

.published-survey-popup :global .ant-modal-content .ant-modal-footer .noButton {
  background-color: #bdbdbd;
}

.published-survey-popup :global .ant-table-tbody > tr > td {
  padding: 2px;
  font-size: 16px;
  color: #7f7f7f;
  border-color: #f0f0f0;
}

.published-survey-popup
  :global
  .ant-table.ant-table-small
  .ant-table-thead
  .ant-table-column-sorters {
  padding: 5px 0;
}

.published-survey-popup :global .ant-table-thead .ant-table-cell {
  padding: 2px;
  font-size: 12px;
}

.published-survey-popup
  :global
  .ant-table.ant-table-small
  .ant-table-thead
  > tr
  > th {
  background: white;
  color: black;
  font-size: 16px;
  text-align: center;
}

.published-survey-popup :global .ant-table-content table {
  border: none;
}

.published-survey-popup :global .ant-table-column-sorters svg,
.published-survey-popup :global .ant-table-filter-column svg {
  color: white;
  font-size: 12px;
}

.published-survey-popup
  :global
  .ant-table.ant-table-small
  .ant-table-tbody
  > tr
  > td {
  word-break: break-word;
}

.published-survey-popup :global .ant-modal-body {
  padding: 16px;
}

.published-survey-popup :global .ant-modal-body {
  padding-bottom: 0;
}

.published-survey-popup :global .ant-pagination-item a {
  color: #bfbfbf;
}

.published-survey-popup :global .ant-pagination-item-link .anticon svg {
  color: #88cdd5;
}

.published-survey-popup :global .ant-pagination-item-active {
  border-color: #88cdd5;
}

.published-survey-popup :global .ant-pagination-item-active a {
  color: #88cdd5;
  font-weight: bold;
}

.published-survey-popup
  :global
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #88cdd5;
}

.published-survey-popup :global .ant-modal-close-x {
  color: black;
}

.published-survey-popup
  :global
  .ant-table-tbody
  > tr.ant-table-row-selected
  > td {
  background: #eef9fa;
}

.published-survey-popup :global .ant-table-content .ant-radio-inner::after {
  background: #88cdd5;
}

.published-survey-popup
  :global
  .ant-table-content
  .ant-radio-wrapper:hover
  .ant-radio,
.published-survey-popup :global .ant-table-content .ant-radio .ant-radio-inner,
.published-survey-popup
  :global
  .ant-table-content
  .ant-radio:hover
  .ant-radio-inner,
.published-survey-popup
  :global
  .ant-table-content
  .ant-radio-input:focus
  + .ant-radio-inner {
  border-color: #88cdd5;
  height: 20px;
  width: 20px;
}

:global .ant-table-filter-dropdown .ant-dropdown-menu-item,
:global .ant-table-filter-dropdown .ant-dropdown-menu-submenu-title {
  color: #7f7f7f;
}

:global
  .ant-table-filter-dropdown
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  display: none;
}

.radioGroup :global .ant-pagination {
  display: flex;
  justify-content: space-between;
}

:global .ant-table-filter-dropdown .ant-dropdown-menu-item-selected {
  background-color: white;
}

:global .ant-table-filter-dropdown .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7f7f7f;
  border-color: #7f7f7f;
}

:global .ant-table-filter-dropdown .ant-btn {
  color: #88cdd5;
}

:global .ant-radio .ant-radio-inner {
  border-color: #d9d9d9 !important;
}

:global .ant-table-filter-dropdown .ant-btn-primary {
  color: white;
  background: #88cdd5;
  border: #88cdd5;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .published-survey-popup :global .ant-table-tbody > tr > td {
    padding: 0;
    font-size: 12px;
  }
  .published-survey-popup
    :global
    .ant-table.ant-table-small
    .ant-table-thead
    > tr
    > th {
    background: white;
    color: black;
    font-size: 12px;
  }
}
