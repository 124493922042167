#top_section {
  position: relative;
  min-height: 90vh;
  background-image: url("../../assets/landing_waves.png");

  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.for_BG {
  background-color: var(--primary-color);
}

#top_section .container .main_content {
  width: 85%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#top_section .container .descriptionx {
  font-size: 15pt;
}
#top_section .container .smallx {
  font-size: 15px;
  margin-top: 2.5rem;
}

#top_section .container .free_btn {
  background-color: #004479;
  border-radius: 30px;
  border: transparent;
  outline: none;
  box-shadow: none;
  font-size: 16pt;
  color: white;
  font-weight: bold;
  padding: 10px 40px;
  transition: all 0.5s;
}
#top_section .container .free_btn:hover {
  background-color: #fff;
  color: #004479;
  text-decoration-line: none;
  transform: translateY(-15%);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.61);
  -moz-box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.61);
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.61);
}

@media screen and (max-width: 500px) {
  #top_section .container .main_content h1 {
    font-size: 1.5rem;
  }
  #top_section .container .descriptionx {
    font-size: 12pt;
  }
  #top_section .container .main_content {
    top: 30%;
  }
}
