.grid-container {
  display: flex;
}

.action-icon {
  margin: auto 0px;
}

.inputFields {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.inputFields > div {
  margin: 2px;
}

.fields {
  flex-grow: 1;
  display: flex;
}

.fields > div {
  margin: 2px;
}

.firstField {
  flex-grow: 2;
}

.secondField {
  display: flex;
  flex-grow: 1;
}

.secondField > span {
  margin-right: 2px;
}

.controlFields {
}

.controlFieldsSpecial {
}

.controlFields span,
.controlFieldsSpecial span {
  margin-right: 4px;
}

.gridInput {
  display: flex;
}

.choiceNumber {
  margin: auto 2px;
}

.fields > div.controls {
  margin: auto 5px;
  min-width: 50px;
}

.uploadButton :global .ant-upload {
  height: 100%;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
  margin: 0;
  width: 70px;
  white-space: normal;
  padding: 0px 15px;
  font-size: 12px;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .fields {
  }
  .inputFields {
  }
  .controlFields {
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
  .controlFields {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
