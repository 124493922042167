.basic-questions-collapse {
  margin: 15px 0;
  border-radius: 5px;
  background-color: #f5f6f8;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: black;
}
.formsPage .sidenav {
  position: relative;
}

.topbarForms {
  height: 25%;
  align-items: center;
  font-size: 24px;
  padding: 0px 15px;
  background-color: rgb(246, 144, 130);
  width: 100%;
}

.customModal .ant-modal-close-x {
  color: white;
  background-color: #dddee0;
  border-radius: 50%;
  margin: 10px;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-style: normal;
  line-height: 30px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header div span {
  color: rgb(255, 148, 128);
  font-weight: bold;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header[aria-expanded="true"] {
  background-color: var(--bg, #71cfd7);
  color: black;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header[aria-expanded="true"]
  div
  span {
  color: white;
}

.ant-btn.ant-btn-lg.start-survey {
  width: 300px;
}

.moderated-survey.ant-collapse-item {
  border: 2px solid #ff9480;
  text-align: center;
  margin-bottom: 30px;
}

.unmoderated-survey.ant-collapse-item {
  border: 2px solid #71cfd7;
  text-align: center;
  margin-bottom: 30px;
}

.moderated-survey.ant-collapse-item.ant-collapse-item-active,
.unmoderated-survey.ant-collapse-item.ant-collapse-item-active {
  border: 1px solid #d9d9d9;
}

.moderated-survey.ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header {
  background-color: #ff9480;
}

.unmoderated-survey.ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header {
  background-color: #71cfd7;
}

.ant-btn.ant-btn-lg.informational-video-btn span {
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .ant-btn.ant-btn-lg.informational-video-btn {
    font-size: 12px;
  }
  .ant-btn.ant-btn-lg.start-survey {
    font-size: 12px;
    width: 250px;
  }
}
