.grid-container {
  display: flex;
}

.grid-container input {
  color: #004479;
}

.fields {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.inputFields {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.fields > div,
.inputFields > div {
  margin: 2px;
}

.inputFields > div {
  flex-grow: 1;
}

.controlFields {
  min-width: 50px;
  text-align: center;
}

.controlFields input {
  text-align: center;
}

.controls span {
  margin-right: 4px;
}

.choiceNumber {
  margin: auto 5px;
}

@media (max-width: 500px) {
  .grid-container {
  }
  .fields {
  }
  .controlFields {
  }
  .inputFields {
  }
  .choiceNumber {
    text-align: center;
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
