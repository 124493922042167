body {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  color: #404040;
}
.ant-spin {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.45);
  z-index: 10001;
}

.ant-tooltip {
  z-index: 0;
}

.shadow-box {
  position: relative;
  padding: 20px 45px;
  border-radius: 6px;
  background-color: #fff;
  color: #004479;
  border: 1px solid #004479;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* INPUT BOX */
.input-border {
  background-color: #ffff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 3px;
  border: none;
}

.input-border::placeholder {
  color: #404040;
}

.input-border .ant-input-number-input::placeholder {
  color: #404040;
}

.input-border .ant-select-selection-placeholder {
  color: #404040;
}

.input-border.ant-select-single:not(.ant-select-customize-input)
  .ant-select-arrow {
  color: #404040;
}

.admin-page-container {
  border: 1px solid #004479;
  padding: 20px 40px;
}

/* MODAL */
.ant-modal-close-x {
  color: white;
}

.ant-modal-title {
  color: white;
}

.ant-modal-header {
  background-color: #004479;
}

.ant-modal-body {
  padding-bottom: 0px;
}

.ant-modal-footer {
  text-align: center;
  border-top: none;
}

.ant-modal-content {
  border-radius: 5px;
}

/* BLUE BG */
.blue-bg {
  background-color: #ecf9fa;
  padding: 20px;
}

@media only screen and (max-width: 1000px) {
  .shadow-box {
    padding: 5px 10px;
  }
  .admin-page-container {
    padding: 20px 5px;
  }
}

.ant-select-arrow {
  color: #004479;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.react-tagsinput-remove:hover {
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.input-container .react-tagsinput {
  background-color: #fff;
  border: 0;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  transition: all 0.3s;
  border-radius: 5px;
}

/* .input-container .react-tagsinput:hover {
  border: 0.2em solid #80bdff;
}

.input-container .react-tagsinput:active {
  border: 0.2em solid #80bdff;
} */

.input-container .react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100%;
}

.emails {
  width: 60%;
}

@media only screen and (max-width: 407px) {
  .emails {
    width: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.02rem;
}

:root {
  --primary-color: #ff9480;
  --main-transition: opacity 0.3s ease;
  --signin-button: rgb(31, 82, 129);
}

body {
  margin: 0;
}

body[data-new-theme] {
  background-color: #f5f5f5;
}

a {
  text-decoration: none;
}
