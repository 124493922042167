.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  color: #495057;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
}
.input-box :global input {
  background-color: #f5f5f5;
}

.input-box:focus {
  border: none;
}
.input-box:hover {
  border: none;
}
