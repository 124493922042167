.multipleChoice .ant-radio-inner {
  height: 18px;
  width: 18px;
  background-color: white;
  border-color: #004479;
}

.multipleChoice .ant-radio-inner:hover {
  border-color:  #004479;
}

.multipleChoice .ant-radio-checked .ant-radio-inner {
  border-color:  #004479;
}

.multipleChoice .ant-radio-inner::after {
  height: 16px;
  width: 16px;
  background-color:  #004479;
}