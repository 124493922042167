.form-component {
  display: flex;
  box-shadow: rgb(0 0 0 / 4%) 0px 2.8px 2.2px, rgb(0 0 0 / 5%) 0px 6.7px 5.3px,
    rgb(0 0 0 / 6%) 0px 12.5px 10px, rgb(0 0 0 / 7%) 0px 22.3px 17.9px,
    rgb(0 0 0 / 9%) 0px 41.8px 33.4px, rgb(0 0 0 / 12%) 0px 100px 80px;
  border-radius: 20px;
}

.form-section-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  letter-spacing: 1px;
  color: white;
  padding-top: 30px;
  border-radius: 10px 0px 0px 10px;
}

.form-section-2 {
  min-width: 350px;
  background: white;
  padding: 20px;
  border-radius: 0px 10px 10px 0px;
}

.site-title {
  font-weight: bold;
  margin: 5px 0px;
  font-size: 18px;
  padding: 0px 30px;
}

.login-title {
  font-size: 30px;
  padding: 0px 30px;
}

.bg-image {
  width: 40vh;
  padding: 0px 30px;
}

.go-to-login-button {
  text-align: center;
  height: 50px;
  padding: 10px;
  background: rgb(130, 194, 197);
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 0px 0px 0px 10px;
}

.button {
  height: 50px;
  letter-spacing: 2;
}

.forget-password {
  margin-top: 5px;
  color: rgb(47, 47, 48);
  font-size: 12px;
  display: block;
  text-align: end;
}

.main-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .form-component {
    margin: 40px 0;
    overflow: auto;
  }
  .form-component {
    flex-direction: column;
  }
  .site-title {
    font-size: 12px;
    text-align: center;
  }
  .login-title {
    font-size: 20px;
    text-align: center;
  }
  .bg-image {
    width: 30vh;
    text-align: center;
  }
  .form-section-1 {
    border-radius: 10px 10px 0px 0px;
  }
  .form-section-2 {
    border-radius: 0px 0px 10px 10px;
    max-width: 300px;
    width: 300px;
    padding: 35px;
  }
  .go-to-login-button {
    width: 50%;
    margin: 5px auto;
    margin-bottom: 10px;
    border-radius: 0px;
    padding: 12px;
    font-size: 10px;
    height: 40px;
  }
  .form-component :global .ant-form-item {
    margin-bottom: 12px;
  }
  .button {
    height: 50px;
    letter-spacing: 0;
    width: 100%;
  }
  .main-page {
    height: auto;
  }
}
