.copy-survey-popup :global .ant-modal-content {
  padding: 20px;
}
.copy-survey-popup :global .ant-modal-content .ant-modal-close-x {
  color: black;
}

.copy-survey-popup
  :global
  .ant-modal-content
  .ant-modal-footer
  button
  + button {
  margin-left: 40px;
}

.copy-survey-popup :global .ant-modal-content .ant-modal-footer button {
  color: white;
  border: none;
  font-weight: bold;
  padding: 6.4px 30px;
}

.button,
.button:hover,
.button:focus {
  color: white;
  border: none;
  padding: 6.4px 30px;
  margin: 5px !important;
  height: 40px;
  min-width: 178px;
  border-radius: 4px;
  text-transform: uppercase;
}
