.star-rating-container {
  text-align: center;
  margin: 5px;
  border: 1px solid rgb(0, 68, 121);
  border-radius: 10px;
  padding: 6px;
  color: rgb(0, 68, 121);
}
.star-rating-container h3, .star-rating-container h1 {
  margin: 0;
  color: rgb(0, 68, 121);
  font-weight: bold;
}
.star-rating-container :global .widget-ratings {
  display: flex !important;
}
