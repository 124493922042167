.textarea {
  border: 1.25px solid #bfbfbf;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  resize: none;
  border-radius: 5px;
  outline: none;
  padding: 0.5em;
  transition: all 0.3s;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff9480' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff9480' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0px;
  background-position: top 0.1em right 0.1em;
  height: 76px;
  min-width: 350px;
}
.textarea:focus,
.textarea:hover {
  border: none;
  box-shadow: 0 0 0 0.2em #71cfd7;
}
.textarea-invalid {
  padding-right: 1.75em;
  background-size: 1.5em;
}
.question-number-icon {
  margin-top: auto !important;
  margin-bottom: auto !important;
  border-radius: 50%;
  border: 0.5px solid #00233f;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  font-size: 110%;
  text-align: center;
}

.question-icon {
  margin-top: auto !important;
  margin-bottom: auto !important;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  width: 24px;
  font-size: 110%;
  text-align: center;
}

.question-box {
  border: 1.25px solid #bfbfbf;
  padding: 0.8em;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  background-color: #fff;
  transition: all 0.3s;
}

.question-box > div,
.question-box > hr {
  margin: 5px 0;
}

.question-box .question-common-component {
  display: flex;
  flex-grow: 1;
}

.question-box .question-common-component > div,
.question-text-type > div,
textarea {
  margin: 2px;
}

.question-text-type {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.question-buttons {
  display: flex;
  flex-flow: column wrap;
  flex-grow: 1;
}

.question-buttons button {
  margin: 2px;
}

.question-grid {
  grid-column: 2/4;
}

.question-grid-extra {
  grid-column: 2/4;
  grid-column-start: 1;
}

.question-box-close {
  position: relative;
  top: calc(3px + 12px);
  right: calc(-100% + 12px + 3px);
  font-size: 24px;
  text-align: center;
  color: #404040;
  background-color: #fff;
  visibility: hidden;
  cursor: pointer;
}

.visible {
  visibility: visible;
}

@media (max-width: 400px) {
  .question-box {
  }
  .question-buttons {
  }
  .question-grid {
    grid-column: 2/3;
  }
}

@media (min-width: 1225px) {
  .question-box {
  }
  .question-grid {
    grid-column: 2/4;
  }
}

.question-button {
  border: 1.25px solid #bfbfbf;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  width: 100%;
  color: #00233f;
  overflow: hidden;
  transition: none !important;
}

.question-button:hover {
  background-color: #667e92;
  color: white;
  border-color: #667e92;
}

.question-button :global .ant-select-selector,
.question-button :global .ant-select-selector .ant-select-selection-item {
  transition: none !important;
}

.select-arrow {
  color: #00233f;
}
.select-arrow-active {
  color: white;
}
.question-button-active,
.question-button-active:hover,
.question-button-active:active {
  background-color: #00233f !important;
  color: white !important;
}

.hr {
  background-color: #bfbfbf;
  width: 100%;
  grid-column: span 3;
}

.publish-button {
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  color: rgb(26, 67, 117);
}

.publish-button:hover {
  color: white;
  background-color: rgb(26, 67, 117);
}
