.grid-container {
  max-width: 650px;
  margin: auto;
}

.inputFields > div {
  display: flex;
  margin: 8px 0;
}

.inputFields > div > div:nth-child(1) {
  min-width: 90px;
}

.inputFields > div > div:nth-child(2) {
  flex-grow: 1;
}

.likert {
  border: 1.25px solid #004791;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-column: 2/4;
}

.likert-labels {
  display: flex;
  justify-content: space-between;
}

.likert-label {
  width: 100px;
  word-break: break-word;
  text-align: center;
  padding: 5px;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1.25px solid #004791;
  margin: 0.5em 0px;
}

.text {
  grid-column: 1/2;
}

.text-visible {
  display: none;
  grid-column: 1/2;
}

@media (max-width: 525px) {
  .grid-container {
  }
  .likert {
    border: 1.25px solid #004791;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    grid-column: 1/2;
  }
  .likert-labels {
    display: flex;
    justify-content: space-between;
  }
  .likert-label {
    width: 70px;
  }
  .text {
    display: none;
  }
  .text-visible {
    display: inline;
  }
  .input,
  .bar {
    grid-column: 1/2;
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .likert-label {
    width: 200px;
  }
}
