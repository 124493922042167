.survey-container {
  background-color: white;
  color: #004479;
  padding: 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.center {
  text-align: center;
  font-size: 18px;
  max-width: 600px;
  margin: 160px auto 0px;
  border: 1px solid #004479;
  padding: 15px;
}

.answerBox {
  max-width: 800px;
  margin: 0px auto 10px;
}

@media only screen and (max-width: 768px) {
  .center {
    font-size: 14px;
  }
}