.date-div {
  border: 1.2px solid #004479;
  width: 100%;
  text-align: center;
  padding: 0.4em;
  border-radius: 5px;
  cursor: pointer;
}

.lb-grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  width: 100%;
  margin: 10px;
  background-color: #edf8f9;
  padding: 10px;
  border-radius: 10px;
}

.calendar {
  font-size: 6px !important;
}

.date-div-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));
  justify-items: center;
  padding: 1em;
  row-gap: 8px;
  column-gap: 8px;
}

.time-div-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  justify-items: center;
  padding: 1em;
  row-gap: 8px;
  column-gap: 8px;
}

@media (max-width: 768px) {
  .lb-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

@media (max-width: 380px) {
  .date-div-container,
  .time-div-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    max-width: 247.5px;
    width: 247.5px;
    justify-items: center;
    margin: 1em;
    row-gap: 4px;
    column-gap: 4px;
  }
  .date-div {
    width: 60px;
    text-align: center;
    padding: 0.4em;
    border-radius: 5px;
  }
}

@media (max-width: 310px) {
  .calendar {
    font-size: 8px !important;
  }
}
