.new-panel-option {
  padding: 5px 10px;
  cursor: pointer;
}

.new-panel-option:hover {
  background-color: aliceblue;
}

.new-panel-box {
  padding: 5px 10px;
}

.button,
.button:hover,
.button:focus {
  color: white;
  border: none;
  font-weight: bold;
  background-color: #88cdd5;
  margin: 5px !important;
  height: 30px;
  min-width: 100px;
  border-radius: 20px;
}
