.character {
  font-size: 30px;
}

.ant-rate-star-second {
  color: #869FB1;
}

.ant-rate-star-full {
  color: white;
}

.rating_container {
  background-color: #0A3157;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

@media only screen and (max-width: 450px) {
  .character {
    font-size: 15px;
  }
}

@media only screen and (max-width: 300px) {
  .character {
    font-size: 10px;
  }
}