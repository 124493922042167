.grid-container {
  display: flex;
}

.grid-container > div,
.fields > div,
.inputFields > div,
.controlFields > div,
.controlFields > span {
  margin: 2px;
}

.fields {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
}

.inputFields,
.controlFields {
  display: flex;
}

.inputFields {
  flex-grow: 1;
  flex-wrap: wrap;
}

.inputFields > div {
  flex-grow: 1;
}

.controlFields {
  margin: 0;
}

.uploadButton :global .ant-upload {
  height: 100%;
}

.uploadButton :global .ant-btn {
  border: 1px solid #004479;
  border-radius: 5px;
  color: #004479;
}

.action-icon {
  margin-right: 4px;
}

.gridInput {
  display: flex;
}

.choiceNumber {
  margin: auto 2px;
}

.inputFields,
.controlFields {
}

@media (max-width: 500px) {
  .grid-container {
  }
  .inputFields {
  }
  .controlFields {
  }
  .fields {
  }
  .choiceNumber {
    text-align: center;
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
  .fields {
  }
  .controlFields {
  }
}

.grid-container :global .choice-numberCircle {
  display: inline;
}

.grid-container .menu-outlined {
  display: none;
}

.grid-container :global .action-icon {
  visibility: hidden;
}

@media screen and (max-width: 1000px) {
  .grid-container :global .action-icon {
    visibility: visible;
  }
}

.grid-container:hover :global .action-icon {
  visibility: visible;
}

.grid-container:hover :global .choice-numberCircle {
  display: none;
}

.grid-container:hover .menu-outlined {
  display: inline;
}
