.topbar {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 40px;
  align-items: center;
  font-size: 24px;
  padding: 0px 15px;
  width: 100%;
}

.hamburger {
  display: none;
}

.menu-drawer .ant-drawer-body {
  padding: 0px;
}

@media only screen and (max-width: 1000px) {
  .hamburger {
    display: block;
    margin-bottom: 12px;
    color: white;
    border: 1px solid white;
    border-radius: 3px;
    background-color: #004479;
  }
  .topbar {
    display: block;
    z-index: 100;
  }
  .logo {
    display: none;
  }
}
