.img-rating-charater {
  font-size: 30px;
}

.image-rating-child {
  margin-bottom: 30px;
}

.image-rating-child:last-child {
  margin-bottom: 0px;
}

@media only screen and (max-width: 450px) {
  .img-rating-charater {
    font-size: 15px;
  }
}

@media only screen and (max-width: 300px) {
  .img-rating-charater {
    font-size: 10px;
  }
}