.header-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.header-select {
  width: 100%;
}

@media (max-width: 490px) {
  .header-buttons {
    display: none;
  }

  .header-arrow {
    display: none;
  }

  .header-buttons-span {
    display: none;
  }

  .header-buttons-span-select {
    width: 100%;
  }
}

@media (min-width: 491px) {
  .header-select {
    display: none;
  }

  .header-buttons-span {
    flex: 1 1 74px;
  }
  .header-buttons-span-select {
    display: none;
  }

  .header-arrow {
    font-size: 200%;
    flex: 1 1;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 4px;
    background-color: #00233f;
  }

  .header-buttons,
  .header-buttons:hover,
  .header-buttons:active,
  .header-buttons:focus {
    font-size: 18px;
    background-color: #00233f;
    border: 0;
    color: #ffffff;
    height: max-content;
    width: 100%;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 0px;
  }

  .header-buttons[disabled] {
    background-color: #00233f;
    color: #ffffff;
    opacity: 0.6;
    pointer-events: none;
  }
  /* .header-buttons:hover {
    flex: 1 1 74px;
    background-color:#667E92;
    min-width: 74px;
    border: 0;
    color: #FFFFFF;
  }
  .header-buttons:active {
    flex: 1 1 74px;
    background-color:#FF9480;
    min-width: 74px;
    border: 0;
    color: #FFFFFF;
  }
  .header-buttons:focus {
    flex: 1 1 74px;
    background-color:#4d7ca1;
    min-width: 74px;
    border: 0;
    color: #404040;
  } */

  /* .header-buttons > span {
    padding-top: 0.55em;
    padding-bottom: 0.55em;
  } */
}
