.textarea {
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  resize: none;
  border-radius: 5px;
  outline: none;
  padding: 0.5em;
  transition: all 0.3s;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ff9480' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0px;
  background-position: top 0.1em right 0.1em;
  height: 76px;
  border: none;
}
.textarea:focus,
.textarea:hover {
  /* box-shadow: 0 0 0 0.2em #80bdff; */
}
.textarea-invalid {
  padding-right: 1.75em;
  background-size: 1.5em;
}

.question-box {
  padding: 0.8em;
  width: 100%;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 135px;
  row-gap: 8px;
  column-gap: 8px;
  background-color: #fff;
  transition: all 0.3s;
  margin: 1em 0;
}

.question-buttons {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 8px;
  align-items: stretch;
}

.question-grid {
  grid-column: 1/3;
}

@media (max-width: 400px) {
  .question-box {
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
    padding: 0.8em;
    width: 100%;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    column-gap: 8px;
    background-color: #fff;
    transition: all 0.3s;
    margin: 1em 0;
  }

  .question-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 8px;
    align-items: stretch;
  }

  .question-grid {
    grid-column: 1/2;
  }
}

.question-button {
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  width: 100%;
  color: #404040;
  overflow: hidden;
  transition: none !important;
  height: 32px;
}
.question-button:hover {
  background-color: #667e92;
  color: #ffff;
}

.select-arrow,
.select-arrow-active {
  color: #404040;
}
.select-arrow:hover,
.select-arrow-active:hover {
  color: #ffff;
}
