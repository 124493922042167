.slider-type .ant-slider-mark-text {
  color: white;
}

.slider-type .ant-slider-handle {
  width: 20px;
  height: 20px;
}

.slider-type .ant-slider-dot {
  width: 14px;
  height: 14px;
}

.slider-type .ant-slider-rail,
.slider-type .ant-slider-track,
.slider-type .ant-slider-step {
  height: 8px;
}

.slider-bottom-marks {
  display: flex;
  justify-content: space-between;
}

.slider-label {
  max-width: 30%;
}

@media only screen and (max-width: 900px) {
  .ant-slider.slider-type {
    min-width: 70%;
  }

  .slider-label {
    font-size: 14px;
  }
}