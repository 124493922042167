.settings {
  color: #404040;
  font-size: 18px;
  /* margin-bottom: 1.5em; */
  max-width: 850px;
}

.payment-method .ant-radio-wrapper {
  width: 100%;
}

.option {
  padding: 10px;
  background-color: #f5f5f5;
  margin-right: 3%;
  color: #404040;
  min-width: 110px;
  margin-bottom: 3%;
  margin-left: 0px !important;
  border-radius: 5px;
}

.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  color: #495057;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
}
.input-box :global input {
  background-color: #f5f5f5;
}

.input-box:focus {
  border: none;
}
.input-box:hover {
  border: none;
}

.access-selector {
  width: 144px;
}

.access-selector :global .ant-select-selector {
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  color: #01223f;
}

@media only screen and (max-width: 450px) {
  .settings {
    font-size: 14px;
  }
}
