.delete-panel-confirm-modal :global .ant-modal-content {
  padding: 20px;
}
.delete-panel-confirm-modal :global .ant-modal-content .ant-modal-close-x {
  color: black;
}

.delete-panel-confirm-modal
  :global
  .ant-modal-content
  .ant-modal-footer
  button
  + button {
  margin-left: 40px;
}

.delete-panel-confirm-modal
  :global
  .ant-modal-content
  .ant-modal-footer
  button {
  color: white;
  border: none;
  font-weight: bold;
  padding: 6.4px 30px;
}

.delete-panel-confirm-modal
  :global
  .ant-modal-content
  .ant-modal-footer
  .yesButton {
  background-color: #40e0d0;
}

.delete-panel-confirm-modal
  :global
  .ant-modal-content
  .ant-modal-footer
  .noButton {
  background-color: #a5b3b8;
}
