.lblue-container {
  background-color: #bae0eb;
  margin-top: 1.2em;
  padding: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bold-text {
  font-weight: 700;
  color: #2c3e50;
  font-size: 1rem;
}

.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  color: #495057;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
}
.input-box :global input {
  background-color: #f5f5f5;
}

.input-box:focus {
  border: none;
}
.input-box:hover {
  border: none;
}

.brand-name {
  background-color: #71cfd7;
  color: white;
  padding: 12px;
  height: auto;
  border-radius: 6px;
}
.brand-name::placeholder {
  color: white;
}

.add-question-button {
  width: 70%;
  background-color: #00233f;
  color: white;
  height: 32px;
  margin-top: 1.25em;
  text-align: center;
  height: 40px;
}

.add-question-button:focus,
.add-question-button:active {
  background-color: #00233f;
  color: white;
}

.add-question-button:hover {
  background-color: white;
  color: #00233f;
}

.grey-question-button {
  color: #00233f;
  background-color: #f2f2f2;
}

.grey-question-button:active {
  color: #00233f;
  background-color: #d8d8d8;
}

.grey-question-button:focus {
  color: #00233f;
  background-color: #d8d8d8;
}

.add-question-icon {
  font-size: 1.2em;
}

.upload-logo-container {
  background-color: #71cfd7;
  color: white;
  height: 200px;
  width: 200px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.logo-container {
  width: 200px;
  height: 200px;
  position: relative;
}

.logo-container img {
  object-fit: contain;
}

.logo-container div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eeeeee80;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  opacity: 0;
}

.logo-container div:hover {
  opacity: 1;
}

.guide-upload-container {
  background-color: #71cfd7;
  color: white;
  display: flex;
  width: 250px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.guide-container {
  background-color: #71cfd7;
  color: white;
  display: flex;
  width: 250px;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 5px;
}

.survey-time-select {
  max-width: 85%;
  flex: 100%;
}

.survey-time-select :global .ant-select-selector {
  background-color: #f5f5f5 !important;
  border: none !important;
}

.dblue-text > h3,
.dblue-text > p {
  color: #004479;
  margin: 0;
  line-height: 20px;
}

.lblue-container > input {
  width: 55%;
  border-radius: 5px;
}

@media (max-width: 510px) {
  .lblue-container {
    background-color: #bae0eb;
    margin-top: 1.2em;
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 2px;
  }
  .lblue-container > input {
    margin-top: 0.5em;
    width: 100%;
    border-radius: 5px;
  }
}

.radio-button {
  padding: 0.5em;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  width: 13em;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  background-color: #ffffff;
}

.radio-button-mobile {
  padding: 0.3em;
  border-radius: 0.25rem;
  align-items: center;
  width: 13em;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  background-color: #f2f2f2;
}

.selected-radio-button {
  padding: 0.5em;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  width: 13em;
  cursor: pointer;
  background-color: #71cfd7;
  color: white;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

.radio-button > label,
.selected-radio-button > label {
  cursor: pointer;
  font-size: 1em;
}

.radio-button-circle {
  color: #004479;
  border: 2px solid #004479;
  border-radius: 50%;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 0.75em;
}

.selected-radio-button > .radio-button-circle {
  color: white;
  border: 2px solid white;
  border-radius: 50%;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 0.75em;
}

.grid-container {
  margin-top: 1.2em;
  display: grid;
  grid-template-columns: 1fr 13em 13em;
  align-items: center;
  row-gap: 0.5em;
  column-gap: 0.25em;
  width: 100%;
}

.span-col-2-4 {
  grid-column: 2/4;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.span-col-2-4 > .radio-button,
.span-col-2-4 > .selected-radio-button {
  width: 8.5em;
}

@media (min-width: 430px) and (max-width: 580px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr 13em;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
    width: 100%;
  }
  .span-col-2-3 {
    grid-column: 2/3;
  }
  .span-col-2-4 {
    grid-column: 2/3;
  }
  .span-col-2-4 > .radio-button,
  .span-col-2-4 > .selected-radio-button {
    width: 13em;
    margin-bottom: 0.25em;
  }
  .last-button {
    margin-bottom: 0.75em;
  }
}

@media (max-width: 430px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
  }
  .span-col-2-3 {
    grid-column: 1/2;
    justify-self: center;
  }
  .span-col-2-4 {
    grid-column: 1/2;
    justify-content: center;
  }
  .span-col-2-4 > .radio-button {
    width: 13em;
    margin-bottom: 0.25em;
    justify-self: center;
  }
  .span-col-2-4 > .radio-button,
  .span-col-2-4 > .selected-radio-button {
    width: 13em;
    margin-bottom: 0.25em;
  }
  .last-button {
    margin-bottom: 0.75em;
  }
}
