.box {
  margin-bottom: 25px;
  background-color: #eee;
  padding: 20px;
  font-size: 16px;
  color: #004479;
  border-radius: 5px;
}

.edit-mode {
  margin-bottom: 25px;
  background-color: #F79083;
  padding: 20px;
  font-size: 16px;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .box {
    font-size: 14px;
  }
}