.img-multiple-choice {
  text-align: left;
}

.img-multiple-choice .ant-radio {
  margin-top: 10px;
}

.img-multiple-choice .ant-radio-inner {
  height: 16px;
  width: 16px;
  background-color: #0C4675;
  border-color: white;
}

.img-multiple-choice .ant-radio-checked .ant-radio-inner {
  border-color: white;
}

.img-multiple-choice .ant-radio-inner::after {
  height: 16px;
  width: 16px;
  background-color: white;
}

.img-multi-option-container {
  display: flex; 
  align-items: center; 
  margin-bottom: 20px;
}

.img-multi-option-container:last-child {
  margin-bottom: 0px;
}