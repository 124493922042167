.explainer-video-btn,
.explainer-video-btn:hover,
.explainer-video-btn:focus,
.explainer-video-btn:active {
  background-color: #ff9480;
  color: white;
  border-radius: 4px;
  border: none;
}

.confused {
  background-color: #194475;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 18px;
}

@media (max-width: 340px) {
  .confused {
    padding: 10px;
  }
  .confused > span {
    font-size: 0.8em;
  }
  .confused-video-button {
    font-size: 0.8em;
    padding: 2px 6px;
  }
}
.confused-video-button {
  background-color: #f2f2f2;
  color: #404040;
  border-radius: 180px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  margin-top: 5%;
  border-color: #f2f2f2;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  background-color: #ffffff;
  min-height: 40px;
}
.confused-video-button:hover {
  background-color: #667e92;
  color: white;
  border-color: #667e92;
}
.confused-video-button-text {
  vertical-align: middle;
  margin-right: 5%;
  padding-top: 3%;
}
.video-content-container {
  display: flex;
  width: 80%;
}
