/* DESKTOP TABLE  */
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  border-right: 1px solid #bae0eb;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  border-right: 1px solid #bae0eb;
}

.ant-table-thead .ant-table-cell {
  background-color: #00233f;
  color: white;
}

.ant-table-small .ant-table-thead .ant-table-cell {
  background-color: #00233f;
  color: white;
}

/* .ant-table-content table {
  border: 1px solid #bae0eb;
} */

/* HEADER CELL PADDING */
.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 5px 4px;
}

/* DATA CELL PADDING */
.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 16px 4px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 5px 0px;
}

/* MOBILE TABLE */
.card {
  border: 1px solid #004479;
  margin-bottom: 30px;
}

.table-row {
  display: flex;
  color: #004479;
}

.card .table-row:not(:last-child) {
  border-bottom: 1px solid #004479;
}

.row-key {
  width: 50%;
  padding: 5px 10px;
  border-right: 1px solid #004479;
}

.row-value {
  width: 50%;
  align-self: center;
  padding: 5px 10px;
  word-break: break-all;
}

.ant-table-content {
  overflow: hidden;
}
@media only screen and (max-width: 1150px) {
  .user-survey .ant-table.ant-table-small .ant-table-thead > tr > th,
  .pricing .ant-table.ant-table-small .ant-table-thead > tr > th {
    font-size: 10px;
  }
  .user-survey .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .pricing .ant-table.ant-table-small .ant-table-tbody > tr > td {
    font-size: 10px;
  }
}

.history-table .ant-table-thead::before {
  content: " ";
  position: absolute;
  height: 54px;
  width: 32px;
  left: -30px;
  top: 0px;
  background-color: #01223f;
  border-radius: 8px 0 0 8px;
}

.history-table .ant-table-thead::after {
  content: " ";
  position: absolute;
  height: 54px;
  width: 32px;
  right: -30px;
  top: 0px;
  background-color: #01223f;
  border-radius: 0 8px 8px 0;
}
