.list-numberCircle {
  border-radius: 50%;
  padding: 3px 7px;
  border: 1px solid white;
  color:white;
  text-align: center;
  font: 12px Arial, sans-serif;
  margin-right: 15px;
}

.ranking-box {
  margin-bottom: 20px;
}

.ranking-box:last-child {
  margin-bottom: 0px;
}