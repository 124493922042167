.ant-checkbox-group.img-checkboxes {
  text-align: left;
}

.img-checkboxes .ant-checkbox {
  margin-top: 10px;
}

.img-checkboxes .ant-checkbox-inner {
  height: 18px;
  width: 18px;
  background-color: #004479;
}

.img-checkboxes .ant-checkbox-checked .ant-checkbox-inner {
  border-color: white;
}

.img-checkboxes-label {
  color: white;
  font-size: 14px;
  word-break: break-word;
  margin-left: 16px;
}