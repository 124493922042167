.h5 {
  color: #404040;
  font-size: 1.5rem;
  margin: 20px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

@media (max-width: 400px) {
  .h5 {
    font-size: 110%;
  }
}

.next-button {
  margin-top: 4%;
  font-size: 110%;
  background-color: #00233f;
  border: 0;
  color: white;
  height: max-content;
  /* width: 20%; */
  min-width: 20%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 5px;
}
@media (max-width: 500px) {
  .next-button {
    width: 100%;
  }
}
.next-button:hover {
  background-color: #00233f;
  color: white;
}
