.grid-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.grid-container > div {
  margin: 2px;
}

.grid-container > div:nth-child(1) {
  flex-grow: 1;
}

.starButtons {
  display: flex;
}

.starButtons > div {
  margin: 2px;
}

.select {
  min-width: 80px;
}

.stars-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 1.25px solid #004479;
  height: 32px;
  align-items: center;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
}

.star {
  flex: 1 1;
  font-size: 16px;
  color: #004479;
  padding: 10px;
}

.select :global .ant-select-selector,
.select :global .ant-select-selector .ant-select-selection-item {
  transition: none !important;
}

@media (max-width: 600px) {
  .grid-container {
  }
  .star {
    padding: 5px;
  }
  .starButtons {
    padding: 0 30px;
  }
}

@media (min-width: 1225px) {
  .grid-container {
  }
}
