.upload-csv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0 0;
}

.upload-button {
  color: white;
  border: none;
  font-weight: bold;
  padding: 6.4px 30px;
  background-color: #88cdd5;
}

.upload-button:hover,
.upload-button:focus {
  color: white;
  background: #88cdd5;
  border-color: none;
}
