.h5 {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 340px) {
  .h5 {
    font-size: 0.85em;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.add-question-button {
  width: 70%;
  background-color: #00233F;
  color: white;
  height: 32px;
  margin-top: 1.25em;
  text-align: center;
  height: 40px;
}

.add-question-button:focus, .add-question-button:active {
  background-color: #00233F;
  color: white;
}

.add-question-button:hover {
  background-color: white;
  color: #00233F;
}

.add-question-icon {
  font-size: 1.2em;
}

.grey-question-button {
  color:  #00233F;;
  background-color: #F2F2F2;
}

.grey-question-button:active {
  color:  #00233F;;
  background-color: #D8D8D8;
}

.grey-question-button:focus {
  color:  #00233F;;
  background-color: #D8D8D8;
}
