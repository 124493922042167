.published-form-box {
  height: 270px;
  border-radius: 5px;
  border: 1px solid #004479;
  color: #404040;
  padding: 0.5rem;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.center-box {
  background-color: #00c194;
  color: white;
  border-radius: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 2.5rem;
}


.scheduled-calls-box {
  background-color: #004479;
  color: white;
  border-radius: 1.5rem;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 2.5rem;
  cursor: pointer;
}
