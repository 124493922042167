.card {
  background-color: rgb(252, 252, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 10px;
  box-shadow: 0 0 3px 1px rgb(241, 241, 241);
  text-align: center;
  margin: 1em;
  cursor: pointer;
  font-weight: normal;
}

.card .heading {
  padding: 0.4em 0;
  font-size: 1.2rem;
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .card .heading {
    font-size: 1rem;
  }
  .card {
    margin: 0.5rem;
  }
}
