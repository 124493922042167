.input-box {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem;
  color: #495057;
  font-size: 16px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.input-box :global input {
  background-color: #f5f5f5;
}

.input-box:focus {
  border: none;
}

.input-box:hover {
  border: none;
}

.project-container {
  position: relative;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  column-gap: 16px;
  cursor: pointer;
}

.project-container:hover {
  box-shadow: 0 1px 4px #8a8a8a;
}

.add-project-container {
  background-color: white;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  display: flex;
  column-gap: 16px;
  cursor: pointer;
  padding: 16px;
  align-items: center;
}

.add-project-container:hover {
  box-shadow: 0 1px 4px #8a8a8a;
}