.header-container{
    background: #024679;
    padding: 15px 20px;
    position: fixed;
    width: 100%;
    z-index: 10;
}
.header-container .login-button,
.header-container .menu-button{
    background: #ff9480;
    border: none;
    border-radius: 3px;
    color: white;
    padding: 11px 40px;
    font-size: 16px;
    height: auto;
}
.header-container.mobile-header .login-button,
.header-container.mobile-header .menu-button{
    padding: 3px 22px;
}

.header-container.mobile-header .login-button-container{
    text-align: right;
}

.header-container.mobile-header .menu-button{
    background: #024679;
    padding-left: 9px;
    padding-right: 17px;
}
.header-container .app-title a{
    font-size: 24px;
    line-height: 48px;
    color: white;
}

.header-container.mobile-header{
    display: none;
    background: white;
    padding: 8px 10px;
}

.header-container .mobile-toggle-menu{
    display: none;
}

.header-container .desktop-menu-item a{
    color: white;
}

.header-container .desktop-menu-item{
    color: white;
    padding: 14px 15px;
    margin-right: 11px;
    font-size: 18px;
}

.header-container .mobile-toggle-menu .menu-item{
    text-align: center;
    border: 1px solid #024679;
    font-size: 18px;
    border-radius: 3px;
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    .header-container.desktop-header{
        display: none;
    }
    .header-container.mobile-header{
        display: flex;
    }
    .header-container .mobile-toggle-menu{
        display: block;
    }
}
