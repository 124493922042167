#main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
  color: white;
  height: 40vw;
  background-color: var(--signin-button);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#main img {
  position: absolute;
  width: 70vw;
  height: auto;
  top: -10.98vw;
  text-align: center;
}
#main img.laptop-video {
  position: absolute;
  width: 50vw;
  height: auto;
  top: -6.98vw;
  text-align: center;
}
#main video {
  border-radius: 5px !important;
  position: absolute;
  top: -9vw;
  background-color: #000;
  width: 50%;
  height: 84.3%;
  text-align: center;
}
#main .row {
  margin-top: 2em;
  position: absolute;
  bottom: 2vw;
}
#main .row p.heading {
  font-size: 34px;
}

@media screen and (max-width: 500px) {
  #main .row p.heading {
    font-size: 20px;
    top: 20vw;
  }
  #main img {
    width: 100vw;
    top: -55.98vw;
  }
  #main img.laptop-video {
    width: 70vw;
    top: -49.98vw;
  }
  #main .row {
    bottom: auto;
  }
  #main .row p.heading {
    font-size: 20px;
  }
}
