.home-feedback h1{
    margin-top: 30px;
    font-size: 39px;
}

.home-feedback .ant-col{
    padding: 20px;
}

.home-feedback .home-point{
    font-size: 20px;
    margin-bottom: 18px;
}

.home-feedback .home-point img{
    display: inline-block;
    height: 30px;
    margin-right: 15px;
}

.home-feedback .ant-btn{
    background: #70ced7;
    border: none;
    border-radius: 3px;
    color: white;
    padding: 17px;
    font-size: 16px;
    height: auto;
    width: 80%;
}

/*.home-feedback .text-col{*/
/*    padding-left: 40px;*/
/*}*/

.home-feedback .image-container img{
    width: 100%;
    max-width: 600px;
}

@media only screen and (max-width: 767px) {
    .home-feedback .ant-btn{
        width: 100%;
    }
    .home-feedback h1{
        font-size: 35px;
    }
    .home-feedback .text-col{
        padding-left: 20px;
    }
}
