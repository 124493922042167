.button {
  padding: 0.4rem 0.8rem;
  margin: 0.4rem;
  border-radius: 4px;
  background-color: #bae0eb;
  color: #004479;
  cursor: pointer;
  font-size: 1.05rem;
  font-weight: 500;
}

.button.selected {
  background-color: #004479;
  color: white;
  padding: 0.4rem 0.8rem;
  margin: 0.4rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.option-button {
  background-color: white;
  color: #004479;
  padding: 0.4rem 0.8rem;
  margin: 0.6rem 0.8rem 0 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.button-icon {
  color: #004479;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.option-button-icon {
  margin-right: 0.4rem;
  cursor: pointer;
}

.option-button-icon::after {
  color: #004479;
  background-color: #004479;
}

.button-icon.selected {
  color: white;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5em;
}

.question-box {
  background-color: #bae0eb;
  width: 100%;
  color: #004479;
  margin: 0.6rem;
  padding: 0.8rem;
  border-radius: 5px;
}

.lb-container {
  background-color: #bae0eb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1.5em;
  width: 100%;
  margin-top: 1.2em;
}

.db-text>h3,
.db-text>p {
  color: #004479;
  margin: 0;
  line-height: 20px;
}

.grid-container {
  margin-top: 1.2em;
  display: grid;
  grid-template-columns: 1fr 13em 13em;
  align-items: center;
  row-gap: 0.5em;
  column-gap: 0.25em;
  width: 100%;
}

@media (min-width: 430px) and (max-width: 580px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr 13em;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
    width: 100%;
  }
}

@media (max-width: 430px) {
  .grid-container {
    margin-top: 1.2em;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    row-gap: 0.5em;
    column-gap: 0.25em;
  }
}

.bold-text {
  font-weight: 700;
  color: #2c3e50;
  font-size: 1rem;
}

.add-question-button {
  width: 100%;
  background-color: #004479;
  color: white;
  font-weight: 700;
  height: 32px;
  margin-top: 1.25em;
  text-align: center;
  height: 40px;
}

@media (max-width: 320px) {
  .add-question-button {
    width: 100%;
    background-color: #004479;
    color: white;
    font-weight: 700;
    margin-top: 1.25em;
    text-align: center;
    height: 40px;
    font-size: 12px;
  }
}

.emails {
  width: 60%;
}

@media (max-width: 407px) {
  .emails {
    width: 100%;
  }
}

.add-question-button:focus,
.add-question-button:active {
  background-color: #004479;
  color: white;
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

.add-question-button:hover {
  background-color: #fff !important;
  color: #004479 !important;
}

.add-question-icon {
  font-size: 1.2em;
}

.grey-container {
  background-color: #FAFAFA;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
}

.grey-question-button {
  color: #004479;
  background-color: #d8d8d8;
}

.modal-container :global .ant-modal-body {
  padding-bottom: 2px;
}

.question-container:hover {
  background-color: #F0F0F0;
  border-radius: 5px;
}

.question-container {
  display: flex;
  margin: 8px 0;
  padding: 10px 0;
  cursor: pointer;
}

.question-container-selected {
  display: flex;
  margin: 8px 0;
  padding: 10px 0;
  cursor: pointer;
  background-color: #B9E8F7;
  border-radius: 5px;
}


.typography {
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  .typography {
    font-size: 16px;
  }
}

.panel-container {
  margin-bottom: 20px;
}

.panel-container :global .ant-collapse-header {
  background-color: white !important;
  color: rgb(160, 160, 160) !important;
  padding: 0 !important;
}

.panel-container :global .ant-collapse-content-box {
  padding: 12px 0px !important;
}