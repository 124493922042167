.grid-container {
    display: grid;
    grid-template-columns: 1fr 30fr;
    column-gap: 8px;
    margin: 5px auto;
  }
  
  .action-icon {
    margin: auto 0px;
  }
  
  .inputFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
  }
  
  .fields {
    display: grid;
    grid-template-columns: 1fr 135px;
    column-gap: 8px;
  }
  
  .controlFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
  }
  
  .controlFieldsSpecial {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    column-gap: 4px;
  }
  
  .controlFields span,
  .controlFieldsSpecial span {
    margin-right: 4px;
  }
  
  .gridInput {
    display: flex;
  }
  
  .controlFieldsSpecial .gridInput {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  :global .uploadButton .ant-upload-select-text div.button {
    display: inline-block;
    font-size: 12px;
    padding: 0px 12px;
    border: 1px solid #004479;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    color: #004479;
    width: 100%;
  }
  
  .choiceNumber {
    margin: auto;
  }
  
  @media (max-width: 500px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 14fr;
      column-gap: 8px;
      row-gap: 8px;
    }
    .fields {
      grid-template-columns: 1fr;
      row-gap: 8px;
    }
    .inputFields {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 4px;
      row-gap: 4px;
    }
    .controlFields {
      display: grid;
      grid-template-columns: 3fr 3fr;
      column-gap: 4px;
    }
    :global .uploadButton .ant-upload-select-text .ant-upload div.button {
      padding: 9px 12px;
    }
  }
  
  @media (min-width: 1225px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr 20fr;
      column-gap: 8px;
    }
    .fields {
      display: grid;
      grid-template-columns: 1fr 200px;
      grid-column-gap: 8px;
      column-gap: 8px;
    }
    .controlFields {
      grid-template-columns: 1fr 3fr;
    }
  }
  