.fill-green.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  border-color: none;
  background-color: #71CFD7;
}
.ant-radio-button-wrapper::before{
  display: none;
}
.fill-green.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  border-color: none;
  background-color: #71CFD7;
}
/* label.ant-radio-button-wrapper, label.ant-radio-button-wrapper:first-child {    
  border: 4px solid gray;
  background-color: rgb(242, 242, 242);
}  */