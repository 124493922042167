.project-page {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  top: 40px;
  margin-left: 18%;
  right: 0;
  bottom: 0;
}

.no-top {
  top: 0px;
}

.project-page-content {
  flex-grow: 1;
  margin-bottom: 10px;
  padding: 0px 150px;
  /* overflow-y: auto; */
}
.project-page-content.history-page-content {
  padding: 0px 100px;
}

.project-page-inner-topbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  line-height: 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.project-page-inner-topbar .go-back-button {
  position: absolute;
  left: 8px;
}

@media screen and (max-width: 1000px) {
  .project-page {
    left: 0;
    top: 40px;
    margin-left: 0px;
  }
  .project-page-content,
  .project-page-content.history-page-content {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 1080px) {
  .project-page-content {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1225px) {
  .project-page-content {
    padding: 0px 90px;
  }
  .project-page-content.history-page-content {
    padding: 0px 100px;
  }
}

.inner-container {
  background-color: white;
  border-radius: 5px;
  width: 100%;
}
