.select-options:hover {
  background-color: #004479;
  color: white;
}

.select-background.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #004479;
  color: white;
}

.select-background.ant-select-single:not(.ant-select-customize-input) .ant-select-arrow {
  color: white;
}

.actions-column {
  width: 200px;
}

@media only screen and (max-width: 461px) {
  .actions-column {
    width: 100%;
  }
}